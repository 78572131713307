<template>
  <div class="relative">
    <div class="w-screen section-container laybare-seasonal-products-banner relative space-top" v-if="hasSeasonalProductBanner"></div>

    <div class="w-screen section-container laybare-products-banner relative space-top" v-else></div>

    <div class="w-full products-bg text-brown-2 p-10 text-center">
      <h3 class="text-base font-bold mobileL:font-semibold mobileL:text-4xl">CHECK OUR AFFILIATE SHOPS</h3>
      <h5 class=" text-xs mb-3 mobileL:text-2xl mobileL:mb-6">Check the links below</h5>

      <div class="grid grid-cols-1 mobileL:grid-cols-4 mobileL:justify-between items-center gap-5 ">
        <a href="https://www.lazada.com.ph/shop/lay-bare/" @click="handleOnlineShopClick('lazada')" class="border-4 rounded-full border-brown-2 py-2 text-2xl xl:text-3xl 2xl:text-4xl font-semibold flex items-center justify-center" target="_blank">
          <svg
            width="3rem"
            height="3 rem"
            viewBox="0 0 50 50"
            id="a"
            xmlns="http://www.w3.org/2000/svg"><path
            class="lazada-svg"
            d="m34.3823,6.2796l-6.4263,3.7102c-2.1825,1.2601-5.7322,1.2537-7.9285-.0143l-6.4666-3.7335-9.0608,5.2313,19.561,11.2936,19.439-11.2231-9.1177-5.2641Z"/><path
            class="lazada-svg"
            d="m43.5,11.5437v19.2712l-18.5339,10.7006c-.5601.3233-1.2501.3233-1.8101,0L4.5,30.7445V11.4733"/><line
            class="lazada-svg"
            x1="24.061"
            y1="22.7668"
            x2="24.061"
            y2="41.754"/></svg>
            LAZADA
        </a>
        <a href="https://shopee.ph/laybarewaxingsalon" @click="handleOnlineShopClick('shopee')" class="border-4 rounded-full border-brown-2 py-2 text-2xl xl:text-3xl 2xl: font-semibold flex items-center justify-center" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="3rem"
            height="3rem"
            class="shopee-svg"><path
            d="M 25 1 C 19.672869 1 15.604123 5.9531422 15.166016 12 L 5.0585938 12 C 3.9235937 12 2.999376 12.980995 3.0625 14.113281 L 4.7871094 44.285156 
            C 4.9365844 46.922145 7.1369035 49 9.7773438 49 L 40.222656 49 C 42.863851 49 45.063433 46.921831 45.212891 44.285156 L 46.9375 14.115234 C 47.002643 12.982141 
            46.076406 12 44.941406 12 L 34.833984 12 C 34.395877 5.9531422 30.327131 1 25 1 z M 25 3 C 29.036936 3 32.408924 6.8867916 32.835938 12 L 17.164062 12 C 17.591075 
            6.8867916 20.963064 3 25 3 z M 25.080078 18 C 28.920078 18 31.799062 20.060938 32.039062 20.210938 L 31.009766 21.880859 C 30.759766 21.710859 30.310625 21.439844 
            30.140625 21.339844 C 29.120625 20.789844 27.240078 19.970703 25.080078 19.970703 C 21.830078 19.970703 19.480469 21.77 19.480469 24.25 C 19.480469 26.72 21.660234 
            27.699766 25.240234 29.009766 C 28.870234 30.329766 32.970703 31.829609 32.970703 36.599609 C 32.970703 40.189609 29.430156 43.009766 24.910156 43.009766 C 20.920156 
            43.009766 17.640078 40.560156 16.830078 39.910156 L 17.939453 38.259766 C 17.969453 38.289766 21.160156 41.029297 24.910156 41.029297 C 28.270156 41.029297 
            31 39.039609 31 36.599609 C 31 33.439609 28.570547 32.319375 24.560547 30.859375 C 21.260547 29.649375 17.509766 28.28 17.509766 24.25 C 17.509766 20.69 20.760078 
            18 25.080078 18 z"/></svg>
          SHOPEE
        </a>
        <a href="https://www.tiktok.com/@officiallaybare" @click="handleOnlineShopClick('tiktok')" class="border-4 2xl:border-4 rounded-full border-brown-2 py-2 text-2xl xl:text-3xl 2xl: font-semibold flex items-center justify-center" target="_blank">
          <i class="fab fa-tiktok text-5xl mr-4"></i>
          TIKTOK
        </a>
        <a
          href="https://www.edamama.ph/shop/brands/lay-bare"
          @click="handleOnlineShopClick('edamama')"
          class="border-4 2xl:border-4 rounded-full border-brown-2 py-2 text-2xl xl:text-3xl 2xl: font-semibold flex items-center justify-center"
          target="_blank">
          <img class="edamama-svg" src="../../assets/icons/edamama-lb.svg" alt="Edamama">
            EDAMAMA
        </a>
      </div>
    </div>

    <div class="w-full review-bg text-brown-2 py-10 products-bg" id="our-products">
      <div class="w-full bg-green-9 pt-4 pb-0 mobileL:p-5 mb-10 sticky fixed-top z-50 ">
        <div class="overflow-x-auto w-11/12 2xl:w-8/12 mx-auto hide-scroll">
          <ul class="flex list-inside gap-6 2xl:gap-10">
            <li class="flex-none">
              <button class="text-white-1 font-semibold text-2xl hover:text-green-1"
              :class="activeCategory === -1 ? 'text-green-1' : 'text-white-1'" @click="changeActiveCategory(-1)">
                All Products
              </button>
            </li>
            <li class="flex-none" v-for="(category, index) in allCategory" :key="index">
              <button class="text-white-1 font-semibold text-2xl hover:text-green-1"
              :class="activeCategory === category.id ? 'text-green-1' : 'text-white-1'" @click="changeActiveCategory(category.id)">
                {{ category.name }}
              </button>
            </li>
          </ul>
          
        </div>
      </div>

      <div class="w-full px-5 mobileL:px-0 mobileL:w-10/12 mobileL:mx-auto mb-5">
        <div class="grid grid-cols-1 mobileL:grid-cols-2 2xl:grid-cols-3 w-full gap-4 xl:gap-6">
          <ProductCard v-for="(product, index) in products" :key="index" :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProductCard from '../../components/ProductCard.vue';
export default {
  components: {
    ProductCard
  },

  data() {
    return {
      activeCategory: -1,
      products: [],
      hasSeasonalProductBanner: true,
    }
  },
  created() {
    this.getProductCategories({business_unit_id: this.$layBareId});
    this.getProducts({business_unit_id: this.$layBareId});
  },

  methods: {
    ...mapActions('products', [
        'getProducts',
        'getProductCategories'
      ]),

      changeActiveCategory(id) {
        this.activeCategory = id;

        if (id === -1) {
          this.products = this.allProduct;
          return;
        }

        this.products = this.allProduct.filter(product => product.category_id === id);
      },

      handleOnlineShopClick(shop) {
        if (process.env.VUE_APP_NODE_ENV !== "production") return;

        let uniqueID = null;

        if (localStorage.getItem("unique") === null) {
          localStorage.setItem("unique", this.$generateUUID())
          uniqueID = localStorage.getItem("unique");
        } else {
          uniqueID = localStorage.getItem("unique");
        }

        this.$gtag.config({
          'user_id': uniqueID
        });

        this.$gtag.event(`online_store_visit_lb_${shop}`, {
            event_category: 'Online Store Click',
            event_label: shop,
            value: 1
        });
      }
  },
  
  computed: {
    ...mapGetters('products', ['allProduct', 'allCategory']),
  },

  watch: {
    allProduct: {
      immediate: true,
      handler: function(data) {
        if (!data.length) return;
        
        this.products = data;
      }
    }
  }
}
</script>

<style scoped>
  .hide-scroll::-webkit-scrollbar {
    width: 0 !important
  }
  .section-container {
    height: 600px;
  }

  .space-top {
    margin-top: 90px;
  }

  .laybare-seasonal-products-banner {
    background: url('../../assets/images/product-page/seasonal-banners/LB_Aug_Web_Product.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .laybare-products-banner {
    background: url('../../assets/images/popup-message/webstore-banner.jpg') no-repeat;
    background-size: 100% 100%;
  }

  .review-bg {
    background: url('../../assets/images/services-page/lb-plus-review-bg.jpg') no-repeat;
    background-size: cover;
  }

  .products-bg {
    background: #FDE3C2;
  }

  svg, .edamama-svg {
    display: inline;
    margin-right: 1rem;
  }

  .lazada-svg {
    fill:none !important;
    stroke:#937140;
    stroke-linecap:round;
    stroke-linejoin:round;
  }

  .shopee-svg {
    fill: #937140;
  }

  .fixed-top {
    top: 90px
  }

  @media (max-width: 480px) {

    .section-container {
      height: 256px;
    }

    .space-top {
      margin-top: 60px;
    }

    .laybare-products-banner {
      background: url('../../assets/images/product-page/product-mobile-banner.png') no-repeat;
      background-size: 100% 100%;
    }

    .laybare-seasonal-products-banner {
      background: url('../../assets/images/product-page/seasonal-banners/LB_Aug_Mobile_Product.jpg') no-repeat;
      background-size: 100% 100%;
    }

    .fixed-top {
      top: 60px
    }

  }
  @media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }

    .section-container {
      height: calc(100vh - 100px);
    }

    .fixed-top {
      top: 100px
    }
  }

  @media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }

    .fixed-top {
      top: 128px
    }
  }
</style>
